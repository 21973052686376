import { Country } from './country';
import { CurrencyISOCodes } from './currency';
import { TimeTable } from './timeTables';
import { Brand } from './brand';
import { PrinterType } from './printer';
import { ValueOf } from 'utils/utilityTypes';
import { ATCUD } from './atcud';
import { IAddress } from 'models/_address';

interface VenueGroup {
  id: number;
  name: string;
  b2b_event_tracking_key?: string;
  created_at: string;
  updated_at: string;
}

export enum VENUE_ACTIVE_FEATURE_FLAG {
  ACTIVE_MQTT = 'active_mqtt',
  ACTIVE_AGENDA = 'active_agenda',
  ACTIVE_CASH_REGISTER = 'active_cash_register',
  ACTIVE_EXPENSES = 'active_expenses',
  ACTIVE_WAREHOUSE = 'active_warehouse',
  ACTIVE_MARKETING = 'active_marketing',
  ACTIVE_STATISTICS_COLLABORATORS_REPORT = 'active_statistics_collaborators_report',
  ACTIVE_STATISTICS_COMPANY_REPORT = 'active_statistics_company_report',
  ACTIVE_STATISTICS_CUSTOMER_REPORT = 'active_statistics_customer_report',
  ACTIVE_STATISTICS_INVENTORY_REPORT = 'active_statistics_inventory_report',
  ACTIVE_STATISTICS_STOCK_REPORT = 'active_statistics_stock_report',
  ACTIVE_STATISTICS_TREND = 'active_statistics_trend',
  ACTIVE_PROMOTIONS = 'active_promotions',
  ACTIVE_BASIC_PROMOTIONS = 'active_basic_promotions',
  ACTIVE_ADVANCED_PROMOTIONS = 'active_advanced_promotions',
  ACTIVE_CUSTOMER_PRIVACY_MANAGEMENT = 'active_customer_privacy_management',
  ACTIVE_VPOS = 'active_vpos',
  PETTY_CASH = 'petty_cash_enabled',
  ACTIVE_MANAGER_STATISTICS = 'active_manager_statistics',
  ACTIVE_GALLERY = 'active_gallery',
  ACTIVE_CUSTOMER_SUPPORT = 'active_customer_support',
  ACTIVE_LISTING = 'active_listing',
  ACTIVE_MARKETPLACE_PAGE = 'active_marketplace_page',
  ACTIVE_ECOMMERCE = 'active_ecommerce',
  ACTIVE_SMS_CUSTOM_SENDER = 'active_sms_custom_sender',
  FEATURE_ROOM_STAFF_MEMBER = 'feature_room_staff_member',
  FEATURE_MULTI_BOOKING = 'feature_multi_booking',
}

export interface VenueActiveFeatureFlags {
  [VENUE_ACTIVE_FEATURE_FLAG.ACTIVE_MQTT]: boolean;
  [VENUE_ACTIVE_FEATURE_FLAG.ACTIVE_AGENDA]: boolean;
  [VENUE_ACTIVE_FEATURE_FLAG.ACTIVE_CASH_REGISTER]: boolean;
  [VENUE_ACTIVE_FEATURE_FLAG.ACTIVE_EXPENSES]: boolean;
  [VENUE_ACTIVE_FEATURE_FLAG.ACTIVE_WAREHOUSE]: boolean;
  [VENUE_ACTIVE_FEATURE_FLAG.ACTIVE_MARKETING]: boolean;
  [VENUE_ACTIVE_FEATURE_FLAG.ACTIVE_STATISTICS_COLLABORATORS_REPORT]: boolean;
  [VENUE_ACTIVE_FEATURE_FLAG.ACTIVE_STATISTICS_COMPANY_REPORT]: boolean;
  [VENUE_ACTIVE_FEATURE_FLAG.ACTIVE_STATISTICS_CUSTOMER_REPORT]: boolean;
  [VENUE_ACTIVE_FEATURE_FLAG.ACTIVE_STATISTICS_INVENTORY_REPORT]: boolean;
  [VENUE_ACTIVE_FEATURE_FLAG.ACTIVE_STATISTICS_STOCK_REPORT]: boolean;
  [VENUE_ACTIVE_FEATURE_FLAG.ACTIVE_STATISTICS_TREND]: boolean;
  [VENUE_ACTIVE_FEATURE_FLAG.ACTIVE_PROMOTIONS]: boolean;
  [VENUE_ACTIVE_FEATURE_FLAG.ACTIVE_BASIC_PROMOTIONS]: boolean;
  [VENUE_ACTIVE_FEATURE_FLAG.ACTIVE_ADVANCED_PROMOTIONS]: boolean;
  [VENUE_ACTIVE_FEATURE_FLAG.ACTIVE_CUSTOMER_PRIVACY_MANAGEMENT]: boolean;
  [VENUE_ACTIVE_FEATURE_FLAG.ACTIVE_VPOS]: boolean;
  [VENUE_ACTIVE_FEATURE_FLAG.PETTY_CASH]: boolean;
  [VENUE_ACTIVE_FEATURE_FLAG.ACTIVE_MANAGER_STATISTICS]: boolean;
  [VENUE_ACTIVE_FEATURE_FLAG.ACTIVE_GALLERY]: boolean;
  [VENUE_ACTIVE_FEATURE_FLAG.ACTIVE_CUSTOMER_SUPPORT]: boolean;
  [VENUE_ACTIVE_FEATURE_FLAG.ACTIVE_LISTING]: boolean;
  [VENUE_ACTIVE_FEATURE_FLAG.ACTIVE_MARKETPLACE_PAGE]: boolean;
  [VENUE_ACTIVE_FEATURE_FLAG.ACTIVE_ECOMMERCE]: boolean;
  [VENUE_ACTIVE_FEATURE_FLAG.ACTIVE_SMS_CUSTOM_SENDER]: boolean;
}

export enum VENUE_REACHED_LIMIT {
  BOOKINGS = 'venue.bookings',
  CUSTOMER_SMS_NOTIFICATIONS = 'venue.customer_sms_notifications',
}

export enum VENUE_REACHED_LIMIT_RESET_MODE {
  BILLING_MONTH = 'billing_month',
  BILLING_YEAR = 'billing_year',
}

export enum BILLING_DETAILS_STATE {
  ACTIVE = 'active',
  GRACE_PERIOD = 'grace_period',
  BLOCKED = 'blocked',
}

export enum PLAN_IDENTIFIER {
  FREE = 'free',
  BASIC = 'basic',
  LITE = 'lite',
  FULL = 'full',
}

export const DAY_PICKER_VARIANTS = {
  COMPACT: 'compact',
  SWIPE: 'swipe',
} as const;

export type ReachedLimit = {
  count: number;
  reset_mode: VENUE_REACHED_LIMIT_RESET_MODE;
};

export type VenueReachedLimit = Partial<{
  [VENUE_REACHED_LIMIT.BOOKINGS]: ReachedLimit;
  [VENUE_REACHED_LIMIT.CUSTOMER_SMS_NOTIFICATIONS]: ReachedLimit;
}>;

export const LOGO = {
  UALA: 'uala-u-logo',
  TREATWELL: 'logo_tw',
} as const;

export const WHITELABEL = {
  UALA: 'uala',
  TREATWELL: 'treatwell',
} as const;

export type WhiteLabel = ValueOf<typeof WHITELABEL>;

export type DayPickerVariants = ValueOf<typeof DAY_PICKER_VARIANTS>;

export type Venue = VenueActiveFeatureFlags &
  IAddress & {
    brands?: Brand[];
    can_bypass_print?: boolean;
    can_email_receipt?: boolean;
    can_print_receipt?: boolean;
    can_use_widget?: boolean;
    country: Country | null;
    country_id?: number | null;
    cube_mac_address?: string | null;
    currency_iso_code?: CurrencyISOCodes;
    created_at: string;
    customers_first_last_name?: boolean;
    detailed_monitoring_enabled?: boolean;
    electronic_invoicing_enabled?: boolean;
    electronic_invoicing_api_configured?: boolean;
    email?: string | null;
    feature_multi_booking?: boolean;
    feature_room_staff_member?: boolean;
    fiscal_day_recap_email?: string | null;
    formatted_address?: string | null;
    hygienic_activities_description?: string | null;
    //TODO: investigate why, how and if this is possible @mauri
    id: number | null;
    listing_image_url?: string | null;
    locale?: string | null;
    api_refresh_rate?: number;
    main_printer_ip_address?: string | null;
    main_printer_type?: PrinterType | null;
    secondary_printer_type?: PrinterType | null;
    max_duration_value?: number | null;
    secondary_printer_ip_address?: string | null;
    invoices_on_secondary_printer: boolean;
    display_invoice_header: boolean;
    skip_receipt_footer: boolean;
    full_vanity_url: string | null;
    print_products_barcode: boolean;
    max_price_value?: number | null;
    name?: string | null;
    phone?: string | null;
    plan: PLAN_IDENTIFIER;
    reached_limits: VenueReachedLimit[];
    show_bundle_name?: boolean;
    mass_checkin_allowed?: boolean;
    mass_checkout_allowed?: boolean;
    siret_code?: string | null;
    company_name?: string;
    address?: string;
    legal_address?: string[];
    vat_number?: string;
    naf_code?: string;
    /**
     * @description
     * if enabled, the BE skip validation on vat_number and fiscal_code fields, so the FE should do
     */
    skip_vat_validation_on_invoice?: boolean;
    time_zone: string;
    time_slot?: number | null;
    time_table: ({
      day: number;
    } & TimeTable)[];
    treatment_responsible_nominated_at?: string | null;
    treatment_responsible_nominated_deadline?: string | null;
    typology_id?: number | null;
    unlock_pin?: string | null;
    updated_at: string;
    vat?: number | null;
    vat_id?: number | null;
    vat_translation: string | null;
    venue_groups: VenueGroup[];
    billing_details: {
      state: BILLING_DETAILS_STATE;
      will_block_access_at: string;
    } | null;
    logo_image_url?: string | null;
    thumb_image_url?: string | null;
    short_code?: string | null;
    fiskaly_client_id?: string;
    whitelabel?: WhiteLabel;
    share_capital?: number;
    ui_settings: {
      max_staff_members_allinone_screen_1920: number;
      max_staff_members_allinone_screen_2560: number;
      calendar_mode: DayPickerVariants;
    };

    print_only_one_receipt: boolean;
    venue_atcud_code?: ATCUD;
    show_vat_on_products_and_services: boolean;
    using_old_billing_plan_logic: boolean;

    iban?: Nullable<string>;

    tax_office_code?: string;
  };
